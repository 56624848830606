import logo from './images/logo.png';
import './App.css';
import AppNavigation from "./AppNavigation";
import branches from "./branches";
import React from "react";

function App() {
	React.useEffect(() => {
		document.title = 'EXPRESdielnička';
	}, []);

	return (
		<div className="py-5">
			<header>
				<div className="container-xl mb-5">
					<div className="d-flex justify-content-center px-3">
						<img src={logo} className="img-fluid" alt="logo"/>
					</div>
				</div>
				<nav className="container-fluid px-5">
					<AppNavigation/>
				</nav>
			</header>
			<hr className="my-5"/>
			<div className="container-xl">
				<h1 className="mb-5 text-center">Kde nás nájdete?</h1>
				<div className="row justify-content-center">
					{branches.map((branch, i) => (
						<div
							key={i}
							className={`col-lg-4 text-center ${i + 1 < branches.length ? 'mb-5 pb-5 mb-lg-0' : ''}`}
						>
							{branch}
						</div>
					))}
				</div>
			</div>
			<hr className="my-5"/>
			<footer className="container-xl">
				<div className="d-flex flex-column align-items-center justify-content-center gap-3">
					<small>&copy; 2023 EXPRESdielnička</small>
				</div>
			</footer>
		</div>
	);
}

export default App;
