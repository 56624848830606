import services from "./services";

function AppNavigation () {
	return (
		<div id="services">
			<div className="row justify-content-center">
				{services.map((service, i) => (
					<div key={i} className="col-sm-6 col-md-3 col-xxl">
						<div className="text-center py-4">
							<img src={service.icon} alt={service.name} className="mb-3" style={{maxHeight: '10rem'}}/>
							<h4>{service.name}</h4>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default AppNavigation;