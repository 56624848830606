import gravirovanie from './images/gravirovanie.png';
import hodinky from './images/hodinky.png';
import klucovaSluzba from './images/klucova-sluzba.png';
import opravaObuvi from './images/oprava-obuvi.png';
import opravaTasiek from './images/oprava-tasiek.png';
import ostrenie from './images/ostrenie.png';
import peciatky from './images/peciatky.png';

const services =  [
	{
		name: 'Kľúčová služba',
		icon: klucovaSluzba
	},
	{
		name: 'Oprava obuvi',
		icon: opravaObuvi
	},
	{
		name: 'Oprava tašiek a opaskov',
		icon: opravaTasiek
	},
	{
		name: 'Ostrenie nožov, nožníc a mlynčekov',
		icon: ostrenie
	},
	{
		name: 'Výroba pečiatok',
		icon: peciatky
	},
	{
		name: 'Gravírovanie',
		icon: gravirovanie
	},
	{
		name: 'Výmena batérií a skracovanie remienkov',
		icon: hodinky
	}
];

export default services;