import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {brands, solid} from '@fortawesome/fontawesome-svg-core/import.macro';

const branches = [
	<>
		<div className="d-flex align-items-center justify-content-center gap-3 mb-4">
			<FontAwesomeIcon icon={solid('location-dot')} className="fa-2x"/>
			<h2 className="mb-0 fw-bolder">Martin</h2>
		</div>
		<dl className="d-flex flex-column justify-content-center mb-4">
			<dt className="text-uppercase">
				<a href="https://goo.gl/maps/1wxrz4q66NRrBHj86" className="text-white">TULIP CENTER</a>
			</dt>
			<dd>(Pltníky 2)</dd>
		</dl>
		<dl className="d-flex flex-column justify-content-center mb-4">
			<dt className="text-uppercase">otvorené:</dt>
			<dd>
				po - so: 9:00 - 13:00, 14:00 - 21:00<br/>
				ne: zatvorené
			</dd>
		</dl>
		<dl className="d-flex justify-content-center mb-4">
			<dt className="visually-hidden">email</dt>
			<dd>
				<a
					href="mailto:info@expresdielnicka.sk"
					className="text-white"
				>info@expresdielnicka.sk</a>
			</dd>
		</dl>
		<dl className="d-flex justify-content-center mb-4">
			<dt className="visually-hidden">Facebook</dt>
			<dd>
				<a
					href="http://www.facebook.com/EXPRESdielnicka"
					className="text-white d-flex align-items-center gap-3"
				>
					<FontAwesomeIcon icon={brands('facebook')} className="fa-2x"/>
					<b>EXPRESdielnička</b>
				</a>
			</dd>
		</dl>
		<dl className="d-flex justify-content-center">
			<dt className="visually-hidden">tel.</dt>
			<dd>
				<a
					href="tel:+421949472819"
					className="btn btn-lg btn-outline-light d-flex align-items-center gap-3"
				>
					<FontAwesomeIcon icon={solid('phone')}/>
					<b>0949 472 819</b>
				</a>
			</dd>
		</dl>
	</>,
	<>
		<div className="d-flex align-items-center justify-content-center gap-3 mb-4">
			<FontAwesomeIcon icon={solid('location-dot')} className="fa-2x"/>
			<h2 className="mb-0 fw-bolder">Žilina</h2>
		</div>
		<dl className="d-flex flex-column justify-content-center mb-4">
			<dt className="text-uppercase">
				<a href="https://g.page/EXPRESdielnickaSolinky" className="text-white">Gaštanová 55</a>
			</dt>
			<dd>(Solinky - pri Smrekovej Kolibe)</dd>
		</dl>
		<dl className="d-flex flex-column justify-content-center mb-4">
			<dt className="text-uppercase">otvorené:</dt>
			<dd>
				po - št: 8:00 - 12:00, 13:00 - 16:00<br/>
				pi - ne: zatvorené
			</dd>
		</dl>
		<dl className="d-flex justify-content-center mb-4">
			<dt className="visually-hidden">email</dt>
			<dd>
				<a
					href="mailto:info@expresdielnicka.sk"
					className="text-white"
				>ivan.gonsenica@expresdielnicka.sk</a>
			</dd>
		</dl>
		<dl className="d-flex justify-content-center mb-4">
			<dt className="visually-hidden">Facebook</dt>
			<dd>
				<a
					href="https://www.facebook.com/expresdielnickasolinky"
					className="text-white d-flex align-items-center gap-3"
				>
					<FontAwesomeIcon icon={brands('facebook')} className="fa-2x"/>
					<b>EXPRESdielnička Solinky / Ivan Gonšenica</b>
				</a>
			</dd>
		</dl>
		<dl className="d-flex justify-content-center">
			<dt className="visually-hidden">tel.</dt>
			<dd>
				<a
					href="tel:+421948636255"
					className="btn btn-lg btn-outline-light d-flex align-items-center gap-3"
				>
					<FontAwesomeIcon icon={solid('phone')}/>
					<b>0948 636 255</b>
				</a>
			</dd>
		</dl>
	</>,
	<>
		<div className="d-flex align-items-center justify-content-center gap-3 mb-4">
			<FontAwesomeIcon icon={solid('location-dot')} className="fa-2x"/>
			<h2 className="mb-0 fw-bolder">Žilina</h2>
		</div>
		<dl className="d-flex flex-column justify-content-center mb-4">
			<dt className="text-uppercase">
				<a href="https://goo.gl/maps/1edEiJkQ3CyngQyg6" className="text-white">Kamenná 4</a>
			</dt>
			<dd>(areál Kinekusu)</dd>
		</dl>
		<dl className="d-flex flex-column justify-content-center mb-4">
			<dt className="text-uppercase">otvorené:</dt>
			<dd>
				po - pi: 8:00 - 11:30, 12:00 - 16:00<br/>
				so - ne: zatvorené
			</dd>
		</dl>
		<dl className="d-flex justify-content-center mb-4">
			<dt className="visually-hidden">email</dt>
			<dd>
				<a
					href="mailto:info@expresdielnicka.sk"
					className="text-white"
				>jozef.kavecky@expresdielnicka.sk</a>
			</dd>
		</dl>
		<dl className="d-flex justify-content-center mb-4">
			<dt className="visually-hidden">Facebook</dt>
			<dd>
				<a
					href="http://www.facebook.com/EXPRESdielnicka"
					className="text-white d-flex align-items-center gap-3"
				>
					<FontAwesomeIcon icon={brands('facebook')} className="fa-2x"/>
					<b>EXPRESdielnička</b>
				</a>
			</dd>
		</dl>
		<dl className="d-flex justify-content-center">
			<dt className="visually-hidden">tel.</dt>
			<dd>
				<a
					href="tel:+421904810578"
					className="btn btn-lg btn-outline-light d-flex align-items-center gap-3"
				>
					<FontAwesomeIcon icon={solid('phone')}/>
					<b>0904 810 578</b>
				</a>
			</dd>
		</dl>
	</>
];

export default branches;